<template>
  <div class="">
    <canvas style="margin: 80px 38px 20px" ref="canvasFront" :width="screenWidth" :height="screenWidth / 2.8"></canvas>
    <canvas style="margin: 0px 38px 10px" ref="canvasBack" :width="screenWidth" :height="screenWidth / 2.8"></canvas>
  </div>
</template>

<script>
import frontBgImage from '@/assets/credential/markCard.png'
import backBgImage from '@/assets/credential/markCard.png'
export default {
  data() {
    return {
      screenWidth: '300',
      frontContent: {
        Name: '陶 明 洋',
        Pinyin: 'TAO MING YANG',
        Code: '豫00021',
        QRCode: 'https://alyimg-zhyl.anluoyun.cn/xaxmzj/images/1720093087185.jpg',
      },
      backContent: null,
      // 初始字体大小
      baseFontSize: 24,
      // 最小字体大小
      minFontSize: 12,
    }
  },
  mounted() {
    console.log(JSON.parse(this.$route.params.obj))
    this.frontContent = JSON.parse(this.$route.params.obj)
    if (this.frontContent.HeadImg) {
      this.frontContent.HeadImg = this.frontContent.HeadImg.replace(/\*/g, '/')
    }
    if (this.frontContent.QRCode) {
      this.frontContent.QRCode = this.frontContent.QRCode.replace(/\*/g, '/')
    }
    if (!this.frontContent.Name) {
      this.frontContent.Name = ''
    }
    if (!this.frontContent.Pinyin) {
      this.frontContent.Pinyin = ''
    }
    if (!this.frontContent.Code) {
      this.frontContent.Code = ''
    }
    this.drawCanvas()
    this.drawCanvasBack()
  },
  methods: {
    //  正面
    drawCanvas() {
      const canvas = this.$refs.canvasFront
      const ctx = canvas.getContext('2d')
      // 加载背景图
      const backgroundImage = new Image()
      backgroundImage.src = frontBgImage

      // 等待背景图加载完成后绘制到画布
      backgroundImage.onload = () => {
        // 计算画布和背景图的宽高比
        const canvasAspectRatio = canvas.width / canvas.height
        const imageAspectRatio = backgroundImage.width / backgroundImage.height
        let renderableWidth, renderableHeight

        // 如果画布的宽高比大于背景图的宽高比，则背景图的宽度将占满画布，高度根据比例缩放
        if (canvasAspectRatio > imageAspectRatio) {
          renderableHeight = canvas.height
          renderableWidth = backgroundImage.width * (renderableHeight / backgroundImage.height)
        } else {
          // 如果画布的宽高比小于背景图的宽高比，则背景图的高度将占满画布，宽度根据比例缩放
          renderableWidth = canvas.width
          renderableHeight = backgroundImage.height * (renderableWidth / backgroundImage.width)
        }

        // 清空画布并绘制背景图
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(backgroundImage, 0, 0, renderableWidth, renderableHeight)

        // 动态计算标题文字大小
        const titleFontSize = Math.min(renderableWidth * 0.11) // 文字大小画布宽度的3%

        const fontFamily = 'Arial'

        // 添加文字
        const text = this.frontContent.Name
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillStyle = '#ffff00'

        // 计算文本的宽度
        const textWidth = ctx.measureText(text).width
        // 计算文本的起始x坐标以使其居中
        const x = canvas.width / 2 - textWidth / 2
        // 设置填充文本的y坐标（假设你想让文本垂直居中，可以设置为canvas高度的一半）
        const y = canvas.height / 2.2
        ctx.fillText(text, x, y)

        // 添加文字拼音
        const textPy = this.frontContent.Pinyin
        // const textPy = 'TUO GUANG SHENG'
        const textSize = this.fontSize(titleFontSize)

        ctx.font = `bold ${textSize}px ${fontFamily}`
        ctx.fillStyle = '#ffff00'
        // 计算文本的宽度
        const textWidth1 = ctx.measureText(textPy).width

        // 计算文本的起始x坐标以使其居中
        const x1 = canvas.width / 2 - textWidth1 / 2
        const y1 = canvas.height / 1.2
        ctx.fillText(textPy, x1, y1)
      }
    },
    // 计算字体大小
    fontSize(size) {
      // 获取画布容器的实际宽度（这里假设它总是小于或等于canvasWidth）
      if (this.frontContent.Pinyin.length > 13) {
        const containerWidth = this.$refs.canvasFront.offsetWidth
        console.log(containerWidth)
        const scale = containerWidth / ((this.frontContent.Pinyin.length * size) / 1)
        // 防止除以0的情况
        const safeScale = Math.max(scale, 0.1) // 至少保留0.1的缩放比例
        // 计算最终的字体大小，并确保它不会低于minFontSize
        return Math.max(size * safeScale, this.minFontSize)
      }
    },
    // 背面
    drawCanvasBack() {
      const canvas = this.$refs.canvasBack
      const ctx = canvas.getContext('2d')
      // 加载背景图
      const backgroundImage = new Image()
      backgroundImage.src = backBgImage

      // 等待背景图加载完成后绘制到画布
      backgroundImage.onload = () => {
        // 计算画布和背景图的宽高比
        const canvasAspectRatio = canvas.width / canvas.height
        const imageAspectRatio = backgroundImage.width / backgroundImage.height
        let renderableWidth, renderableHeight

        // 如果画布的宽高比大于背景图的宽高比，则背景图的宽度将占满画布，高度根据比例缩放
        if (canvasAspectRatio > imageAspectRatio) {
          renderableHeight = canvas.height
          renderableWidth = backgroundImage.width * (renderableHeight / backgroundImage.height)
        } else {
          // 如果画布的宽高比小于背景图的宽高比，则背景图的高度将占满画布，宽度根据比例缩放
          renderableWidth = canvas.width
          renderableHeight = backgroundImage.height * (renderableWidth / backgroundImage.width)
        }

        // 清空画布并绘制背景图
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(backgroundImage, 0, 0, renderableWidth, renderableHeight)

        // 动态计算标题文字大小
        const titleFontSize = Math.min(renderableWidth * 0.12) // 文字大小画布宽度的3%

        const fontFamily = 'Arial'

        const textNo = this.frontContent.Code
        ctx.font = `bold ${titleFontSize}px ${fontFamily}`
        ctx.fillStyle = '#ffff00'

        // 计算文本的宽度
        const textWidth = ctx.measureText(textNo).width

        // 计算文本的起始x坐标以使其居中
        const x = canvas.width / 2 - textWidth / 2

        // 设置填充文本的y坐标（假设你想让文本垂直居中，可以设置为canvas高度的一半）
        const y = canvas.height / 1.47
        ctx.fillText(textNo, x, y)
      }
    },
  },
}
</script>
<style scoped></style>
